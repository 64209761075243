import { Anchor } from "./styles";

import AppLink, { AppLinkProps } from "@/components/AppLink";

type InternalLinkProps = AppLinkProps;

const InternalLink: React.FC<InternalLinkProps> = (props) => {
  const { title, page, params } = props;
  return (
    <AppLink page={page} params={params}>
      <Anchor>{title}</Anchor>
    </AppLink>
  );
};

export default InternalLink;
